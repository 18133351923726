.quote-animation {
  .word {
    position: relative;
  }

  .highlight .word {
    color: $color-exp-maroon;
  }

  .line-mask {
    position: absolute;
    z-index: $z-index-2;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    opacity: 0.8;
  }
}