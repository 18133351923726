// sass-lint:disable no-important
@import 'src/styles/imports';

$desktop-horizontal-padding: 5em;
$join-button-width: 120px;
$footer-top-height: 360px;
$footer-max-width: $breakpoint-large-desktop - 100;
$active-color: #8d8d8d;
$active-color-2: #d1d1d2;
$hover-color-gradient: linear-gradient(270deg, $active-color-2 -63.51%, $active-color 89.48%);

.features {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
}

.feature-flags {
  font-size: 12px;
  margin: 0 1em;
}

.component {
  padding: 2em $desktop-horizontal-padding;
  background-color: var(--themes-colors-primary-dark);
  color: $color-white;

  .footer-top {
    width: 100%;
    max-width: $footer-max-width;
    @include flex-row;
    border-bottom: 1px solid $color-grey-8;
    margin: auto;

    .footer-links {
      @include flex-row;
    }

    .footer-top-left {
      width: 70%;
      min-height: $footer-top-height;
      padding: 2em 0;
      @include flex-col;

      .join-section-mobile-container {
        position: relative;
        display: none;
      }
    }

    .footer-top-right {
      min-height: $footer-top-height;
      padding: 1.5em 2em;
    }

    @media (max-width: 1550px) {
      .footer-top-left {
        min-height: $footer-top-height - 80;
        flex-grow: 1;
      }

      .footer-top-right {
        min-height: $footer-top-height - 80;
        flex-grow: 1;
      }
    }
  }

  .footer-bottom {
    font-size: .65em;
    width: 100%;
    line-height: 1.5em;
    margin: 1em auto;
    color: $color-dark-navy-20;
    font-weight: 500;
    text-align: center;
    white-space: pre-wrap;

    .footer-big-text {
      height: 90px;
      margin-top: 60px;

      p {
        font-size: calc((100vw - 410px) / 15);
        padding-top: 10px;
        color: $color-dark-navy-20;

        &::first-letter {
          // this lines up the e in eXp with the first
          //  column links
          margin-left: -.04em;
        }
      }
    }

    p {
      margin-bottom: .5em;
    }

    * a {
      color: $color-dark-navy-20;
      text-decoration: underline;
    }
  }
}

.block {
  margin-bottom: 2em;

  p {
    font-weight: $font-weight-light;
  }

  .about-exp-links {
    a {
      font-size: 14px;
      display: block;
      margin-bottom: 1.5em;
      color: $color-white;
      font-weight: $font-weight-light;
      text-decoration: none;

      &:hover {
        background: $hover-color-gradient;
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: $font-weight-bold;
        -webkit-text-fill-color: transparent;
      }

      &:active {
        color: $active-color;
        text-decoration: underline;
        -webkit-text-fill-color: inherit;
      }
    }
  }

  .socials {
    display: flex;
    margin: 1.2em 0;

    a {
      margin-right: .7em;

      svg {
        width: 22px;
        height: 22px;

        @include mobile {
          width: 18px;
          height: 18px;
        }
      }

      .social-x-icon {
        &:hover {
          path {
            fill: $active-color;
          }
        }
      }

      .social-ig-icon {
        &:hover {
          path {
            stroke: $active-color;
          }
        }
      }
    }
  }

  .contact {
    margin: 2em 0;

    .contact-item {
      font-size: 14px;
      display: flex;
      margin: .5em 0;
      margin-right: .5em;
      align-items: center;
      flex-direction: row;

      a {
        display: flex;
        margin-bottom: 1em;
        align-items: center;
        justify-content: center;
      }

      svg {
        max-width: 16px;
        max-height: 16px;
        margin-right: .5em;
      }

      path {
        fill: $color-white;
      }

      p {
        padding: 0;
        margin: 0;
        color: $color-white;
      }
    }
  }

  .office-address {
    font-size: 14px;
    line-height: 1.5em;
  }
}

.block1 {
  width: 200px;
}

.block2 {
  width: 180px;
}

.block3 {
  width: 250px;
}

.block4 {
  width: 250px;

  * {
    white-space: pre-wrap;
  }
}

.join-section {
  position: relative;

  .join-image-container {
    overflow: hidden;
    height: $footer-top-height - 80;
    border-top-left-radius: 40%;
    margin: auto;
    aspect-ratio: 1; // sass-lint:disable-line no-misspelled-properties

    img {
      transition: .3s ease;
    }
  }

  @media (max-width: 1550px) {
    .join-image-container {
      height: $footer-top-height - 80;

    }
  }

  &:hover {
    .join-image-container {
      img {
        transform: scale(1.07);
      }
    }
  }

  .join-link {
    position: absolute;
    bottom: 2em;
    left: calc(50% - $join-button-width/2);
    text-decoration: none;

    .join-button {
      font-size: 19px;
      width: $join-button-width;
      height: 54px;
      border: 0;
      font-weight: $font-weight-bold;
    }
  }
}

@media (max-width: 1250px) {
  .component {
    padding: 1em 2em;

    .footer-top {
      padding: 1.5em 0;
      flex-direction: column;

      .footer-top-left {
        width: 100% !important;
      }

      .footer-bottom {
        .footer-big-text {
          @include flex-row;
          align-items: center;
          justify-content: unset;

          p {
            font-size: 110px !important;
            width: calc(100% - 250px);
            line-height: 110%;
            padding-top: 10px;
            letter-spacing: 8px;
            text-wrap: wrap;
          }

          .join-section-mobile-container {
            display: block;

            .join-image-container {
              height: 240px;

              img {
                height: 240px;
              }
            }

            .join-link {

              .join-button {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .component {
    padding: 1em;

    .footer-top {
      padding: 1.5em 0 0;
      border: 0;
      flex-direction: column;

      .footer-top-left {
        width: 100% !important;
        min-height: 460px;

        .footer-links {
          display: flex;
          flex-wrap: wrap;

          .block {
            width: 45%;

            .socials {
              flex-wrap: wrap;
            }
          }
        }

      }

    }

    .footer-bottom {
      .footer-big-text {
        @include flex-col;

        p {
          font-size: calc((100vw - 2em) / 14) !important; // fits exactly the screen width
          width: 100%;
          padding-top: 25px;
          padding-bottom: 45px;
        }

        .join-section-mobile-container {
          display: none;
          align-items: center;
          justify-content: center;

          .join-image-container {
            height: 300px;
            margin: auto;

            img {
              width: auto;
              height: 300px;
            }
          }

          .join-link {

            .join-button {
              font-size: 16px;
            }
          }
        }
      }

      padding-bottom: 8em;
      margin-top: 4em;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .component {
    .footer-bottom {
      padding-bottom: 3em;
    }
  }
}