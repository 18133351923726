// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0 !important;
  border-radius: 50%;
  margin-right: .5em;
  margin-left: .5em;
  background-color: $color-black;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  @media (min-width: 1050px) {
    margin-right: 0;
    margin-left: 1.5em;
  }

  .close-button {
    position: absolute;
    top: -1em;
    right: -1em;
    display: block;
    width: 1.5em !important;
    height: 1.5em !important;
    padding: 0 .5em;
    border: 0;
    border-radius: 50%;
    background: $color-dark-navy;
    color: $color-black;
    cursor: pointer;
  }

  .component:hover .close-button {
    display: block;
  }

  svg {
    width: 2em !important;
    height: 2em !important;
    margin-right: 0 !important;

    path {
      fill: $color-white !important;
    }
  }
}
