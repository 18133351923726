.page-wrapper {
  &.search {
    position: relative;
    min-height: calc(100vh - #{$height-top-nav});

    @media (max-width: $breakpoint-search-mobile) {
      min-height: calc(100vh - (#{$height-bottom-nav-mobile} + #{$height-top-nav-mobile} + #{$height-search-mobile-footer}));

      map {
        height: 100%;
      }

      mobile-footer {
        bottom: #{$height-bottom-nav-mobile};
      }
    }
  }
}
