button,
.button {
  font-size: 16px;
  display: inline-block;
  padding: .65em 1em;
  border: 0;
  border-radius: $border-radius-small;
  background-color: transparent;
  font-family: $font-family-sans-serif;
  cursor: pointer;
  outline: 0;
  transition: all .3s ease;

  &:hover {
    text-decoration: none;
  }

  &.primary {
    border: 1px solid var(--themes-colors-primary);
    border-radius: var(--themes-buttons-border-radius);
    background-color: var(--themes-colors-primary);
    color: $color-white;

    &:hover {
      border-color: var(--themes-colors-tertiary-border-color);
      background-color: var(--themes-colors-primary-inverted-background);
      color: var(--themes-colors-primary-font-color);
    }
  }

  &.secondary {
    border: 1px solid var(--themes-colors-secondary-border-color);
    border-radius: var(--themes-buttons-border-radius);
    background-color: $color-white;
    color: $color-grey-10;

    &:hover {
      background-color: var(--themes-colors-secondary-hover-color);
      color: $color-white;
    }
  }

  &.tertiary {
    border: 1px solid var(--themes-colors-secondary-border-color);
    border-radius: var(--themes-buttons-border-radius);
    background-color: var(--themes-colors-primary-inverted-background);
    color: $color-white;

    &:hover {
      border: 1px solid $color-grey-10;
      background-color: transparent;
      color: $color-grey-10;
    }
  }

  &:disabled {
    cursor: default;
  }
}
