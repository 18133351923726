.named-content {
  max-width: $max-width-text;
  padding: 0 0 2em;
  margin: auto;

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-serif;
  }

  h1 {
    font-size: 32px;
    padding: 1em 0 0;
    font-family: $font-family-serif;
    text-align: left;
  }

  h2,
  h3 {
    font-size: 1.25em;
    font-family: $font-family-serif;
    font-weight: 500;
  }

  h2 {
    margin: 1em 0;
  }

  h3 {
    display: flex;
    margin-top: 1em;
    align-items: center;
  }

  .material-symbols-outlined {
    font-size: 26px;
    margin-right: .4em;
  }

  p {
    line-height: 1.6;
  }

  a {
    color: var(--themes-colors-primary-link-color);

    &:hover {
      color: var(--themes-colors-tertiary-link-color);
    }

    &:focus {
      color: var(--themes-colors-quartenary-link-color);
    }
  }

  strong {
    font-weight: $font-weight-bold;
  }

  em {
    font-style: italic;
  }

  p,
  li {
    font-size: .9375em;
    line-height: 1.5em;
    padding-top: 1em;
  }

  ol {
    margin-top: 1em;

    li {
      list-style-type: decimal;
    }
  }

  li {
    padding: .5em 0 0 1em;
    margin-left: 2em;
  }

  ul {
    margin-top: 1em;

    li {
      list-style-type: disc;

      li {
        list-style-type: circle;

        li {
          list-style-type: square;
        }
      }
    }
  }

  button {
    margin-top: 2em;
  }

  hr {
    margin: 2em auto 0;
  }

  sup {
    font-size: .7em;
    margin-left: .2em;
    vertical-align: top;
  }

  cite {
    font-size: .7em;
    display: block;
    line-height: 1.2em;
    margin-bottom: 1em;
  }

  .updated-at {
    color: $color-sand;
  }

  table {
    font-size: .9375em;
    display: block;
    overflow-x: auto;
    width: 100%;
    margin: 2em 0 1em;
    background-color: $color-white;
    text-align: center;

    tbody {
      width: 100%;
    }

    tr {
      td,
      th {
        min-width: 115px;
        padding: 1em;
        vertical-align: middle;
      }

      th {
        font-weight: $font-weight-bold;
      }
    }

    td:first-child {
      border-top-left-radius: var(--themes-buttons-border-radius-small);
      border-bottom-left-radius: var(--themes-buttons-border-radius-small);
    }

    td:last-child {
      border-top-right-radius: var(--themes-buttons-border-radius-small);
      border-bottom-right-radius: var(--themes-buttons-border-radius-small);
    }

    tr:nth-child(odd) {
      background-color: var(--themes-colors-primary-chart);
    }
  }

  section {
    display: flex;
    margin-top: 1.2em;
    align-items: center;

    .material-symbols-outlined {
      font-size: 30px;
    }

    div {
      display: flex;
      flex-direction: column;

      h4 {
        font-size: .8em;
        color: $color-black-65;
        font-family: $font-family-sans-serif;
      }

      p {
        font-size: 1.2em;
        padding: 0;
        margin: 0;
        font-family: $font-family-serif;
        font-weight: 500;
      }
    }
  }
}

zoo-tip {
  display: block;
  box-shadow: $box-shadow;
  max-width: 900px;
  padding: 1em;
  border-top: 3px solid $color-sand-dark;
  margin: 1em auto 0;
  background-color: $color-white;

  span {
    margin-bottom: .5em;
    color: $color-black;
    font-weight: $font-weight-bold;
  }

  p {
    line-height: 1.5;
  }
}
