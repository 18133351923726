.ct-bar {
  stroke-width: 10px;
  animation: barGraphStretch $transition-duration-slow;
}

.ct-series-a .ct-bar {
  stroke: $color-grey-4;

  &.exp-theme:hover {
    stroke: $color-exp-secondary-dark-navy;
  }
}

.ct-series-b .ct-bar {
  stroke: $color-blue;

  &.exp-theme {
    stroke: $color-exp-primary-brand-royal;
  }
  
  &.exp-theme:hover {
    stroke: $color-exp-secondary-dark-navy;
  }
}

.ct-bar:hover {
  stroke: $color-black-90;
  
}

.ct-grid {
  stroke-dasharray: 0;
}

.ct-horizontal {
  display: none;
}

.ct-vertical {
  stroke: $color-grey-2;
}

.ct-labels span {
  display: block;
}