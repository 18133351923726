@import 'src/styles/imports';
// sass-lint:disable no-misspelled-properties

.component {
  @include flex-center;
  height: 100%;
  background-color: $color-grey-1;
  flex-direction: column;
  container-type: size;

  &.round {
    border-top-left-radius: $border-radius-large;
    border-top-right-radius: $border-radius-large;
  }

  p {
    font-size: 6cqw;
    margin: .5em 0;
    color: $color-grey-10;
  }

  svg {
    width: 136px;
    height: 40%;
  }
}
