// NOTE: classes prefixed by i- use !important
// sass-lint:disable no-important

.i-hidden {
  display: none !important;
}

.i-invisible {
  opacity: 0 !important;
  pointer-events: none !important;
}

.i-capitalize {
  text-transform: capitalize !important;
}

.i-uppercase {
  text-transform: uppercase !important;
}
