$ball-height: 20px;
$bar-height: 8px;

.noUi-target.noUi-horizontal { // sass-lint:disable-line class-name-format
  box-shadow: none;
  height: .5em;
  padding: 0 1.1em 0 .2em;
  border: 0;
  background-color: $color-grey-3;

  .noUi-connect { // sass-lint:disable-line class-name-format
    box-shadow: none;
    background-color: $color-sand;
  }

  .noUi-handle { // sass-lint:disable-line class-name-format
    z-index: $z-index-bump;
    box-shadow: $box-shadow;
    width: $ball-height;
    height: $ball-height;
    border: 1px solid $color-grey-4;
    border-radius: $ball-height / 2;
    cursor: grab;

    &::before {
      content: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: calc(50% - (#{$ball-height} * .6 / 2));
      left: calc(50% - (#{$ball-height} * .6 / 2));
      width: $ball-height * .6;
      height: $ball-height * .6;
      border-radius: 50%;
      background: $color-sand;
    }
  }
}
