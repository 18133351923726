// Source: Paul Irish - http://www.paulirish.com/2012/box-sizing-border-box-ftw/
// Apply a natural box layout model to all elements, but allowing components to change

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
