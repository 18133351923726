// sass-lint:disable no-important
.swiper {
  height: auto;
  padding-bottom: 2em;

  .swiper-button-next,
  .swiper-button-prev {
    top: calc(50% - 5.5em);
    display: flex;
    width: 3em;
    height: 3em;
    border: 2px solid $color-white;
    border-radius: 50%;
    background-color: $color-black-65;
    cursor: pointer;
    opacity: 0;
    align-items: center;
    justify-content: center;
    transition: all $transition-duration ease;

    &::after {
      font-size: 1.25em;
      color: $color-white;
    }
  }

  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 1;
    }
  }

  .swiper-pagination {
    &.swiper-pagination-bullets {
      bottom: .2em;
    }

    .swiper-pagination-bullet-active {
      background: var(--themes-colors-primary);
    }

    .swiper-pagination-bullet:only-child {
      display: inline-block !important;
    }
  }

  &.homes-carousel {
    .swiper-button-next,
    .swiper-button-prev {
      top: calc(50% - 4em);
      opacity: 1;
    }

    .swiper-pagination {
      .swiper-pagination-bullet,
      .swiper-pagination-bullet-active {
        background-color: $color-white;
      }
    }
  }

  &.vip-carousel {
    padding-bottom: 3.2em !important;

    @include desktop {
      border: 1px solid $color-grey-4;
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: calc(50% - 5em);
      opacity: 1;

      @include desktop {
        top: calc(50% - 2em);
      }
    }

    .swiper-pagination {
      bottom: 1.2em;
    }
  }
}
