@import 'src/styles/imports';

.component {
  position: fixed;
  z-index: $z-index-location-picker-screen;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding: 2em;
  background-color: $color-grey-6;

  .brush-stroke {
    position: absolute;
    z-index: $z-index-brush-stroke;
    height: 31.75em;
    opacity: .14;
    pointer-events: none;
  }

  .brush-stroke-1 {
    right: -4em;
    bottom: -11.25em;
    width: 290px;
    pointer-events: none;
    transform: rotate(-165deg);
  }

  .brush-stroke-2 {
    top: -15em;
    left: -20em;
    width: 509px;
    transform: rotate(-290deg);
  }

  .wrapper {
    height: 100%;
    @include layout-container;
  }

  svg {
    height: 1em;

    path {
      fill: $color-white;
    }
  }

  .content {
    display: flex;
    overflow: scroll;
    max-width: 40em;
    height: 100vh;
    padding-top: 3em;
    padding-bottom: 5em;
    margin-right: auto;
    margin-left: auto;
    color: $color-white;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @include no-scrollbar;

    h2 {
      font-size: 30px;
      line-height: 42px;
      font-family: $font-family-sans-serif;
    }

    .disclaimer {
      font-size: 19px;
      margin-top: 1em;
      color: $color-grey-12;
    }

    .outside {
      text-decoration: underline;
      cursor: pointer;
    }

    .choices {
      display: flex;
      margin: 3em 0 1em;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      .choice {
        margin: 0 2em 2em;
        cursor: pointer;

        p {
          font-size: 25px;
          margin-top: 1em;
        }
      }
    }

    @include mobile {
      h2 {
        font-size: 20px;
        line-height: 28px;
      }

      .disclaimer,
      .outside {
        font-size: 16px;
        line-height: 24px;
      }

      .disclaimer {
        max-width: 18em;
      }

      .outside {
        max-width: 16em;
      }

      .choices {
        .choice {
          p {
            font-size: 18px;
            margin-top: .5em;
          }
        }
      }
    }
  }
}

