@import 'src/styles/imports';

.feedback {
  font-size: 14px;
  padding: 1em 1.2em;
  border-radius: $border-radius-medium;
  margin-right: auto;
  margin-bottom: 2em;
  font-weight: $font-weight-bold;

  @media (max-width: $breakpoint-tablet) {
    margin-left: auto;
  }
}
