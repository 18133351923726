html,
body {
  overflow-x: clip;
  background-color: var(--themes-colors-primary-background);
}

body {
  position: relative;
  display: block;
  width: 100%;
  min-width: 320px;
  min-height: $body-min-height;
  color: $color-grey-10;
  font-family: $font-family-sans-serif !important; // sass-lint:disable-line no-important
  -ms-overflow-style: -ms-autohiding-scrollbar; // Hides the scrollbars on IE11 & Edge
  transition: transform .3s ease;

  @media (min-width: 50rem) {
    overflow-x: visible;
  }

  &.dragging {
    cursor: grab;
    user-select: none;
  }

  &.mobile {
    .zopim {
      width: 42px !important; // sass-lint:disable-line no-important
    }
  }

  &.ios.safari {
    // Set class through JS for iPhone/iPod and only.
    // This is for pages that are too small to scroll such as the search page
    min-height: $ios-vertical-height;

    .modal {
      max-height: $ios-vertical-height;
      margin-bottom: $ios-controls-height;
    }

    .account {
      margin-bottom: $ios-controls-height;
    }

    &.mobile-nav-opened {
      @media (max-width: 375px) {
        // Disables main nav open transition time for iOS devices with screen sizes small than iPhone 8.
        // This eliminates animation jitter bug since iOS 11 release.
        // TODO: Remove this when solution is resolved.
        transition: transform 0 ease;
      }
    }
  }

  // Disable transition on older iOS versions to fix issue with modals not being visible
  &.ios-less-than-10.safari:not(&.mobile-nav-opened) {
    transition: none;
  }

  &.internet-explorer {
    .page-wrapper.school-overview {
      min-height: 100%;
    }

    school-page {
      min-height: 200px;

      .upper-content-wrapper {
        margin-bottom: 0;

        &::after {
          display: none;
        }
      }

      .hide-on-ie {
        display: none;
      }
    }
  }

  &.modal-dialog-opened {
    overflow: hidden;
  }

  &.mobile-nav-opened {
    .body-container {
      transform: translate3d(-100%, 0, 0);
    }

    .application {
      height: 100vh;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $color-black-40;
    }

    @include desktop {
      .body-container {
        transform: none;
      }

      &::after {
        content: none;
      }
    }
  }
}

.body-container {
  padding-top: $height-top-nav-mobile;
  transition: transform .3s ease;

  @include desktop {
    padding-top: $height-top-nav;
  }

  &.exp-theme {
    padding-top: $height-exp-top-nav-mobile;

    @include desktop {
      padding-top: $height-exp-top-nav;
    }

    &.home,
    &.home-appraisal,
    &.exp-specialized-services,
    &.exp-specialized-divisions,
    &.income,
    &.relocation,
    &.reo,
    &.sell,
    &.buy,
    &.explore-exp,
    &.my-link-my-lead,
    &.referrals,
    &.join-exp {
      padding-top: 0;
    }
  }
}

.team-create-listings-widget,
.team-listings-widget,
.team-nearby-listings,
.team-nearby-areas,
.buy,
.sell,
.explore-exp,
.my-link-my-lead,
.income,
.relocation,
.reo,
.join-exp,
.agents {
  padding-top: 0 !important; // sass-lint:disable-line no-important
}

.ember-application {
  min-height: calc(100vh - #{$height-top-nav});

  @include mobile {
    min-height: calc(100vh - (#{$height-bottom-nav-mobile} + #{$height-top-nav-mobile}));
  }
}

.page-wrapper {
  min-height: calc(100vh - #{$height-top-nav });
  background-color: $color-white;
}

.hidden {
  display: none;
}

.no-select {
  user-select: none;
}

.center {
  text-align: center;

  > * {
    margin: auto;
  }
}

// TODO: Refactor the following somewhere else
.layout-container {
  @include layout-container;
}

.blur {
  @include blur-text;
  pointer-events: none;
}

.cursor-pointer {
  cursor: default;
}

p {
  font-weight: $font-weight-regular;
}

h1 {
  font-family: $font-family-serif !important; // sass-lint:disable-line no-important
}

h1,
h2,
h3 {
  font-weight: var(--themes-fonts-heading-weight);
}

@include not-desktop {
  * {
    -webkit-tap-highlight-color: transparent;
  }
}

#teconsent { // sass-lint:disable-line no-ids
  width: 0;
  height: 0;
}

// Wrapper for EqualWeb Accessibility widget
#INDWrap * { // sass-lint:disable-line no-ids
  font-family: $font-family-sans-serif !important; // sass-lint:disable-line no-important
}

#INDmenu-btn { // sass-lint:disable-line no-ids
  display: none !important; // sass-lint:disable-line no-important
}

// Override calendar popup in search filters color
.rdp-day_selected, // sass-lint:disable-line class-name-format
.rdp-day_selected:focus-visible, // sass-lint:disable-line class-name-format
.rdp-day_selected:hover { // sass-lint:disable-line class-name-format
  background-color: var(--themes-colors-primary-filters) !important; // sass-lint:disable-line no-important
}
