// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  @include flex-center;
  font-size: .875em;
  border-radius: var(--themes-buttons-border-radius);
  text-align: center;
  white-space: nowrap;
  flex-wrap: nowrap;

  .left-icon {
    margin-right: .5em;
  }

  .right-icon {
    margin-left: .5em;
  }

  &.primary {
    font-weight: 700;

    &.exp-theme {
      font-weight: 400;
    }

    &:hover {
      .modify path {
        fill: var(--themes-colors-primary-font-color);
      }
    }
  
    &:focus {
      border-color: var(--themes-colors-primary-focus-border-color);
      background-color: var(--themes-colors-primary-focus-color);
      color: var(--themes-colors-primary-font-color);
  
      .modify path {
        fill: var(--themes-colors-primary-font-color);
      }
    }
  }

  .modify {
    width: 1.2em;
    height: 1.2em;

    path {
      fill: $color-white;
    }
  }

  &.secondary {
    .modify path {
      fill: $color-black;
    }

    &:hover {
      .modify path {
        fill: $color-white;
      }
    }

    &:active {
      border-color: var(--themes-colors-secondary-focus-border-color);
      background-color: var(--themes-colors-secondary-focus-color);
      color: var(--themes-colors-secondary-background);

      .modify path {
        fill: var(--themes-colors-secondary-background);
      }
    }
  }

  &.tertiary {
    color: var(--themes-colors-primary-font-color);

    &:hover {
      background-color: var(--themes-colors-primary-font-color);
      color: var(--themes-colors-primary-background);

      .modify path {
        fill: var(--themes-colors-primary-background);
      }
    }

    .modify path {
      fill: var(--themes-colors-primary-font-color);
    }

    &:focus {
      background-color: var(--themes-colors-tertiary-background-light);
      color: var(--themes-colors-primary-background);

      .modify path {
        fill: var(--themes-colors-primary-background);
      }
    }
  }
}

.homepage-button-padding {
  font-size: 16px !important;
  padding: 12px 16px !important;
  border-radius: 8px !important;
  font-style: normal;
  font-weight: 400;

  svg {
    padding: 1px;
  }
}
