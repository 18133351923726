// sass-lint:disable no-important
@import 'src/styles/imports';

$desktop-horizontal-padding: 4em;
$join-button-width: 180px;
$footer-top-height: 480px;
$footer-max-width: $breakpoint-large-desktop - 100;
$active-color: #8d8d8d;
$active-color-2: #d1d1d2;
$hover-color-gradient: linear-gradient(270deg, $active-color-2 -63.51%, $active-color 89.48%);

%small-text {
  font-size: 11px;
  line-height: 17px;
  margin-bottom: 1.5em;
}

.features {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
}

.feature-flags {
  font-size: 12px;
  margin: 0 1em;
  color: $color-white;
}

.component {
  padding: 2em $desktop-horizontal-padding;
  background-color: $color-grey-10;
  color: $color-white;

  .footer-top {
    width: 100%;
    max-width: $footer-max-width;
    max-height: 500px;
    padding-top: 4em;
    padding-bottom: 3em;
    @include flex-row;
    border-bottom: 1px solid $color-grey-8;
    margin: auto;
    gap: clamp(80px, 5vw, 200px);

    @media (max-width: 1250px) {
      max-height: none;
      border-bottom: none;
      margin-bottom: 50px;
    }

    .footer-links {
      @include flex-row;
    }

    .footer-top-left {
      display: flex;
      max-width: 1100px;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 1250px) {
        max-width: none;
      }

      .footer-big-text {
        width: 100%;
        margin-top: auto;
        justify-content: center;

        > div {
          height: auto;
        }

        @media (min-width: 1250px) {
            transform: translateY(5px);
        }
      }

      .join-section-mobile-container {
        position: relative;
        display: none;
      }
    }

    .footer-top-right {
      max-height: 500px;
    }

    @media (max-width: 1550px) {
      .footer-top-left {
        flex-grow: 1;
      }

      .footer-top-right {
        flex-grow: 1;
      }
    }
  }

  .footer-bottom {
    font-size: .65em;
    width: 100%;
    max-width: 680px;
    line-height: 16px;
    margin: 3em auto;
    color: $color-dark-navy-20;
    font-weight: 500;
    text-align: center;
    white-space: pre-wrap;

    p {
      margin-bottom: .5em;
    }

    * a {
      color: $color-dark-navy-20;
      text-decoration: underline;
    }
  }
}

.block {
  margin-bottom: 2em;

  p {
    font-weight: $font-weight-light;
  }

  .terms-and-conditions {
    a {
      font-size: 14px;
      display: block;
      line-height: 16px;
      margin-bottom: 1em;
      color: $color-white;
      font-weight: $font-weight-light;
      text-decoration: none;

      &:hover {
        background: $hover-color-gradient;
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: $font-weight-bold;
        -webkit-text-fill-color: transparent;
      }

      &:active {
        color: $active-color;
        text-decoration: underline;
        -webkit-text-fill-color: inherit;
      }
    }
  }

  .socials {
    display: flex;
    margin: 1.2em 0;

    a {
      margin-right: 1em;

      svg {
        width: 22px;
        height: 22px;

        @include mobile {
          width: 18px;
          height: 18px;
        }
      }

      .social-x-icon {
        &:hover {
          path {
            fill: $active-color;
          }
        }
      }

      .social-ig-icon {
        &:hover {
          path {
            stroke: $active-color;
          }
        }
      }
    }
  }

  .contact {
    .contact-item {
      font-size: 14px;
      display: flex;
      margin: 0 .5em 0 0;
      align-items: center;
      flex-direction: row;

      a {
        display: flex;
        margin-bottom: 1em;
        align-items: center;
        justify-content: center;
      }

      svg {
        max-width: 16px;
        max-height: 16px;
        margin-right: .5em;
      }

      path {
        fill: $color-white;
      }

      p {
        font-size: 13px;
        padding: 0;
        margin: 0;
        color: $color-white;
      }
    }
  }

  .office-address {
    font-size: 13px;
    width: 90%;
    line-height: 1.5em;
  }
}

.block1 {
  width: 140px;
}

.block2 {
  width: 180px;
}

.block3 {
  width: 170px;
  margin-right: 20px;
}

.block4 {
  width: 170px;

  .feedback-button {
    width: 100%;
    margin-left: 0;
    color: #3E4042;
  }
}

.join-section {
  position: relative;
  width: 100%;

  .join-image-container {
    overflow: hidden;
    height: $footer-top-height - 110;
    border-top-left-radius: 40%;
    aspect-ratio: 1; // sass-lint:disable-line no-misspelled-properties
    scale: 102%;

    > div {
      height: 100%;
    }

    img {
      height: $footer-top-height - 110;
      transition: .3s ease;
    }
  }

  @media (max-width: 1550px) {
    .join-image-container {
      height: $footer-top-height - 110;
      img {
        height: $footer-top-height - 110;
      }
    }
  }

  &:has(.join-image-container:hover),
  &:has(.join-link:hover) {
    .join-image-container {
      img {
        transform: scale(1.07);
      }
    }
  }

  .join-link {
    position: absolute;
    bottom: 2em;
    left: calc(50% - $join-button-width/2);
    text-decoration: none;

    .join-button {
      font-size: 19px;
      width: $join-button-width;
      height: 54px;
      border: 0;
      font-weight: $font-weight-bold;
    }
  }
}

@media (max-width: 1250px) {
  .component {
    padding: 1em 2em;

    .footer-top {
      padding: 1.5em 0;

      .footer-top-left {
        width: 100% !important;

        .footer-big-text {
          width: 100%;
          margin-top: 2em;
          align-items: center;
          flex-direction: column;
          justify-content: unset;

          p {
            font-size: 110px !important;
            width: calc(100% - 250px);
            line-height: 110%;
            padding-top: 10px;
            letter-spacing: 8px;
            text-wrap: wrap;
          }

          .join-section-mobile-container {
            display: block;
            margin-top: 4.5em;
            
            .join-image-container {
              margin: auto;

              img {
                height: 100%;
              }
            }

            .join-link {
              .join-button {
                font-size: 16px;
              }
            }
          }
        }
      }

      .footer-top-right {
        display: none;
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .component {
    padding: 1em;

    .footer-top {
      padding: 1.5em 0 0;
      border: 0;

      .footer-top-left {
        width: 100% !important;
        min-height: 460px;
        margin-bottom: 30px;

        .footer-links {
          display: flex;
          flex-wrap: wrap;

          .block {
            width: 45%;

            .socials {
              flex-wrap: wrap;
            }
          }
        }

        .footer-big-text {
          width: 100%;
          margin-top: 1.5em;
          @include flex-col;
          justify-content: center;

          .zoocasa-logo {
            height: auto;
          }

          .join-section-mobile-container {
            display: flex;
            width: 100%;
            align-items: left;
            justify-content: left;

            .join-image-container {
              width: 100%;
              height: auto;

              img {
                width: 100%;
              }
            }

            .join-link {
              .join-button {
                font-size: 16px;
              }
            }
          }
        }
      }

      .footer-top-right {
        display: none;
      }
    }

    .footer-bottom {
      padding-bottom: 8em;
      margin-top: 4em;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .component {
    .footer-bottom {
      padding-bottom: 3em;
    }
  }
}

.heading {
  font-size: 20px;
  margin-bottom: 1em;
  color: #D8C19E;
  font-family: $font-family-serif;
}

.app-store-links {
  margin-top: 1em;

  a {
    display: block;
    max-width: 130px;
    margin-bottom: .5em;
  }
}

.exp-logo-container {
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  p {
    font-size: 13px;
    margin-right: 1em;
    margin-bottom: 0;
    color: $color-grey-4;
    font-weight: 600;
  }

  .exp-logo {
    width: 55px;
  }
}

.exp-copyright {
  @extend %small-text;
  width: 180px;
  margin-bottom: 2em !important;
  color: #E8E8E8;
}

.socials {
  display: flex;
  margin: 1.5em 0;

  a {
    margin-right: .7em;

    .invert {
      path {
        fill: $color-white;
      }
    }

    svg {
      width: 26px;
      height: 26px;
    }
  }
}

.office-address {
  font-size: 13px;
  line-height: 1.5em;
}

@media (max-width: calc($max-width-app + 2*$desktop-horizontal-padding)) {
  .block4 {
    padding-left: 0;
  }
}

@media (max-width: $breakpoint-tablet) {
  .hide-mobile {
    display: none !important;
  }

  .footer {
    padding-bottom: 7em;
    flex-direction: column;

    > div {
      width: 100%;
    }
  }

  .block3 {
    margin-bottom: 0;
  }

  .socials {
    a {
      margin: 0.3em;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  .app-store-links {
    justify-content: center;
  }

  .contact {
    display: flex;
    margin-bottom: 0;
    flex-wrap: wrap;
    .contact-item { margin: 0 1em; }
  }

  .contact-item,
  .office-address {
    font-size: 13px;
    width: 90%; 
    line-height: 17px;
  }

  .terms-and-conditions {
    a {
      font-size: 13px;
      display: block;
      margin-bottom: 1.5em;
      color: $color-white;
      font-weight: $font-weight-light;
      text-decoration: none;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .hide-desktop {
    display: none !important;
  }

  .block1 { padding-left: 0; }
  .block4 { padding-right: 0; }
}

.listing-links {
  > a:first-child {
    margin-bottom: 1em;
  }
  > a:last-child {
    margin-bottom: -1em;
  }

  a {
    :first-child {
      margin-bottom: 1em;
    }
    font-size: 14px;
    display: block;
    line-height: 17px;
    margin-bottom: 1em;
    color: $color-white;
    font-weight: $font-weight-light;
    text-decoration: none;

    &:hover {
      background: $hover-color-gradient;
      -webkit-background-clip: text;
      background-clip: text;
      font-weight: $font-weight-bold;
      -webkit-text-fill-color: transparent;
    }

    &:active {
      color: $active-color;
      text-decoration: underline;
      -webkit-text-fill-color: inherit;
    }
  }
}