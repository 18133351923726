@import 'src/styles/imports';
@import 'src/styles/animations';

.component {
  position: relative;
  margin: 0 auto;
  background: linear-gradient(to right, $color-grey-2 0%, $color-grey-3 20%, $color-grey-2 40%, $color-grey-2 100%);
  background-size: 200% 200%;
  animation: shimmer 1s linear 0s infinite;
}
