a,
.link {
  color: var(--themes-colors-secondary-link-color);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $color-grey-10;
    text-decoration: underline;
  }

  &.primary {
    color: var(--themes-colors-primary-link-color);

    &:hover {
      color: var(--themes-colors-primary-link-color);
    }
  }

  &.tertiary {
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }
}

.primary-link {
  border-bottom: 0;
  color: var(--themes-colors-primary-link-color);
  font-weight: bold;
  cursor: pointer;

  &:hover {
    border-bottom: 0;
    text-decoration: underline;
  }
}
