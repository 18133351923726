@import 'src/styles/imports';

.footer {
  display: flex;
  padding: 2em 4em;
  background-color: $color-commercial-footer-bg;
  color: $color-white;
  flex-direction: column;
  justify-content: center;

  .footer-top {
    display: flex;
    width: 100%;
    max-width: $max-width-app;
    padding-bottom: 2em;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    @media (max-width: $breakpoint-side-panel) {
      .item {
        // First two items take 50% width
        &:nth-child(1),
        &:nth-child(2) {
          flex: 1 1 50%;
        }

        // Third item takes 100% width and wraps to next line
        &:nth-child(3) {
          flex: 1 1 100%;
        }
      }
    }

    .footer-logo-container {
      max-width: 270px;
      color: $color-grey-12;

      .footer-logo {
        width: 87px;
      }

      .company-description {
        font-size: 14px;
        margin-top: 1em;
      }
    }

    .footer-links-container {
      display: flex;
      min-width: 400px;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: $breakpoint-tablet) {
        min-width: 0;
        flex-direction: column;
      }
    }

    .footer-links {
      display: flex;
      padding-left: 1em;
      border-left: 1px solid $color-white;
      align-self: flex-start;
      flex-direction: column;
      grid-gap: 1em;

      a {
        color: $color-white;
        transition: transform .3s ease-out;


        &:hover {
          color: $color-blue-dark;
          text-decoration: none;
          transform: translateX(5px);
        }
      }
    }

    .social-column {
      font-size: 14px;
      display: flex;
      max-width: 300px;
      line-height: 2em;
      color: $color-grey-12;
      flex-direction: column;

      @media (max-width: $breakpoint-side-panel) {
        margin-top: 2em;
        text-align: center;
        align-items: center;
      }

      .socials {
        display: flex;
        margin: 1.2em 0;
        flex-direction: row;

        a {
          margin-right: .7em;

          svg {
            width: 36px;
            height: 36px;
            transition: transition .3s ease-out;

            path {
              fill: $color-white;
            }

            &:hover {
              transform: skewY(-2deg) translateY(-3px);

              path {
                fill: $color-blue-dark;
              }
            }

            @include mobile {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }

  .footer-middle {
    font-size: 12px;
    text-align: center;

    h4 {
      margin: 3em 0;
      color: $color-white;
    }

    p {
      margin-bottom: 2em;
      color: $color-grey-12;
    }

    a {
      color: $color-grey-4;
      text-decoration: underline;

      &:hover {
        color: $color-blue-dark;
      }
    }
  }

  .footer-bottom {
    font-size: 15px;
    line-height: 2em;
    padding: 1em;
    border-top: 1px solid $color-grey-2;
    margin-top: 3em;
    margin-bottom: 6em;
    color: $color-white;
    text-align: center;

    a {
      position: relative;
      margin: 0 1em;
      color: $color-white;
      text-decoration: none;
      transition: color .3s ease-in;

      &:hover {
        color: $color-blue-dark;
      }

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -1em;
        width: 1px;
        height: 1em;
        background-color: $color-white;
        transform: translateY(-50%);
      }
    }
  }
}
