input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
textarea {
  font-size: .875em;
  width: 100%;
  min-width: 0;
  height: 50px;
  padding: .5em .8em !important; // sass-lint:disable-line no-important
  border: $border !important; // sass-lint:disable-line no-important
  border-radius: var(--themes-buttons-border-radius) !important; // sass-lint:disable-line no-important
  background-color: $color-white;
  color: $color-grey-10 !important; // sass-lint:disable-line no-important
  font-family: $font-family-sans-serif !important; // sass-lint:disable-line no-important

  &::placeholder {
    font-weight: $font-weight-regular;
    user-select: none;
  }
}
textarea {
  border-radius: var(--themes-buttons-border-radius-medium) !important; // sass-lint:disable-line no-important
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
textarea,
select {
  border-radius: var(--themes-buttons-border-radius);
  transition: all $transition-duration-fast ease-in-out;

  &:not(:disabled):hover {
    border-color: var(--themes-colors-quaternary-border-color); // sass-lint:disable-line no-important
  }

  &:focus {
    border-color: var(--themes-colors-quaternary-border-color) !important;
    outline: 0;
  }

  &:disabled {
    background-color: $color-grey-1;
  }
}

textarea {
  max-width: 100%;
  height: 100px;
  padding: 1em;
}

// Class from TextInput Mixin, included in:
// text-input, number-input, select-field, radio-buttons components
.input-control {
  position: relative;
  display: block;
  margin: 1em 0;

  label {
    display: inline-block;
    overflow: hidden;
    height: $height-input-control;
    line-height: $height-input-control;
    text-align: left;
    cursor: pointer;
    vertical-align: top;
    white-space: nowrap;
  }

  input,
  select {
    height: $height-input-control;
  }

  &.horizontal-mode {
    label { width: 30%; }
    .input-wrapper { width: 70%; }
  }

  &.vertical-mode {
    label,
    .input-wrapper {
      width: 100%;
    }
  }
}
